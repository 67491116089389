import React, { Component, Fragment } from "react";



import axios from "axios";

import { Table, Container, Row, InputGroup, Col, Navbar, NavbarBrand, Nav, NavItem, NavLink, Card, CardBody, CardTitle, CardSubtitle, CardText, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, ButtonGroup, FormGroup, Label, Input } from "reactstrap";
import moment from "moment";
import HeaderComponent from "../header/HeaderComponent";
import Select from "react-select";

import $ from "jquery";
import "datatables.net";
import { is } from "immutable";

const { Base64 } = require("js-base64");

function statusChange(type) {
	const { data } = this.state;
	this.setState({ data: data.set("currentSlamState", type) });
}

function formatBytes(bytes, decimals = 2) {
	if (!+bytes) return "0 Bytes";

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}
class AllPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			project_list: [],
			showModal: false,
			tableData: [],
			selectedData: undefined,
			adminComments: [],
			requestTesetSetList: [],
			tesetSetList: [],
			selected_engin_type: "all",
			selectedMode: "all",
			clone_engine_type: "all",
			exclude_company_member: false,
			mobilemode: false,
			max_rows: 25,
			selected_priority: "all",
			modalImageSrc: "",
			isInfoModalOpen: false,
			currentTask: {},
			currentEnvParm: {},

		};
		statusChange = statusChange.bind(this);
		this.mqttClient = "";
		this.test = React.createRef();
		this.modalRef = React.createRef();
		window.that = this;
	}







	toggleModal = (item) => {

		this.setState({ showModal: !this.state.showModal });
	};


	closeModal = () => {

		this.setState({ isModalOpen: false, modalImageSrc: '', isInfoModalOpen: false });
	};
	onEvalChanged = async (seq, value) => {
		const { data } = this.state;
		const result = await axios({
			method: "Post",
			url: `${this.props.urls[this.props.stage]}/admin/request`,
			data: {
				seq: seq,
				evaluation: value,
			},
			headers: {
				Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
			},
		});
		alert("변경했습니다.");
		this.doShowModal(Base64.encode(JSON.stringify(result.data.data)));
	};
	onValueChanged = (name, value) => {

		console.log("name:", name, ",value:", value)

		this.setState({
			[name]: value

		}, () => {
			if (name == "selectedMode") {
				this.initializeDatatable();
			}
			if (name == "selectedProjectName") {
				this.initializeDatatable();
			}
		});

	};
	handleClickOutside = (event) => {
		if (this.modalRef.current && !this.modalRef.current.contains(event.target)) {
			this.closeModal();
		}
	};
	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClickOutside);
	}
	getEsmCall = async () => {

		await axios({
			url: `${process.env.REACT_APP_API_PATH}/dev/admin/esm`,
			params: {

			},
			type: "get",
			headers: {
				Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
			},
			error: function (xhr, error, code) {
				console.log(error);
			},
		});



	};
	getDataTableAjax = () => {
		const { data } = this.state;

		let mode = this.state.selectedMode

		if (mode == "diverged") {
			mode = "all";
		}
		return {
			url: `${process.env.REACT_APP_API_PATH}/dev/admin/all?status=${this.state.selectedMode}&project_name=${this.state.selectedProjectName}`,
			type: "get",
			headers: {
				Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
			},
			error: function (xhr, error, code) {
				alert("권한이 없습니다.");
			},
		};
	};
	getProjectList = async () => {
		const result = await axios({
			method: "Get",
			url: `${process.env.REACT_APP_API_PATH}/dev/admin/project/list`,
			params: {


			},
			headers: {
				Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
			},
		});
		const projects = JSON.parse(result.data.body).project_list;

		this.setState({ project_list: projects, selectedProjectName: projects[0].project_name }, () => {

			this.initializeDatatable();

		});
	}
	getTaskInfo = async (seq) => {



		const _result = await axios({
			url: `${process.env.REACT_APP_API_PATH}/dev/admin/task/info`,
			params: {
				seq: seq,
			},
			type: "get",
			headers: {
				Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
			},
			error: function (xhr, error, code) {
				alert("권한이 없습니다.");
			},
		});
		const result = JSON.parse(_result.data.body);
		this.setState({
			currentTask: result.task,
			currentEnvParm: result.parmenv,
			isInfoModalOpen: true,
		})
	};
	getColumnSetting = () => {
		const { data } = this.state;
		const thumbnailWidth = 75;
		const thumbnailHeight = 75;

		const that = this;

		let recoPublicURL = this.props.stage == "prod" ? "https://public.metarecon.ai" : "https://stag.recopublic.rlabsdev.com";

		let idx = { title: "idx", data: "idx", searchable: true, className: "dt-center" };
		let seq = { title: "seq", data: "seq", searchable: true, className: "dt-center", width: "10%" };
		let project_name = { title: "project_name", data: "project_name", searchable: true, className: "dt-center", width: "5%" };
		let file_name = { title: "file_name", data: "org_file_name", className: "dt-center", width: "5%" };
		let category_1 = { title: "category_1", data: "category_1", searchable: true, className: "dt-center", width: "5%" };
		let product_id = { title: "product_id", data: "product_id", className: "dt-center", width: "5%" };
		let customer_name = { title: "customer_name", data: "customer_name", className: "dt-center", width: "5%" };
		let client_id = { title: "client_id", data: "client_id", className: "dt-center", width: "5%" };

		// let status = { title: "status", data: "status", className: "dt-center", width: "5%" };

		let status = {
			title: "상태",
			data: "status",
			className: "dt-center",
			width: "5%",
			render: function (data, type, row, meta) {
				if (data == "succeed") {

					return `<a style="color:green; font-weight:bold">${"succeed"}</a>`;

				} else {
					return `<a style="color:blue; font-weight:bold">${data}</a>`;
				}
			},
		};
		let created_datetime = {
			title: "요청 생성시간",
			data: "created_datetime",
			className: "dt-center",
			width: "10%",
			render: function (data, type, row, meta) {
				return moment(data).format("YY-MM-DD HH:mm:ss");
			},
		};
		let requested_datetime = {
			title: "api요청 시간",
			data: "requested_datetime",
			className: "dt-center",
			width: "10%",
			render: function (data, type, row, meta) {
				if (data) {
					return moment(data).format("YY-MM-DD HH:mm:ss");
				} else {
					return "-"
				}
			},
		};
		let completed_datetime = {
			title: "완료 시간",
			data: "completed_datetime",
			className: "dt-center",
			width: "10%",
			render: function (data, type, row, meta) {
				if (data) {
					return moment(data).format("YY-MM-DD HH:mm:ss");
				} else {
					return "-"
				}
			},
		};
		let elasped_time = {
			title: "소요시간",
			data: "requested_datetime",
			className: "dt-center",
			width: "10%",
			render: function (data, type, row, meta) {

				if (row["status"] != "succeed") {
					return "처리 중";
				}
				else {
					const delta = row["completed_unix"] - row["unix"]
					//convert delta to human readable format
					const days = Math.floor(delta / 86400);
					const hours = Math.floor((delta % 86400) / 3600);
					const minutes = Math.floor(((delta % 86400) % 3600) / 60);
					const seconds = ((delta % 86400) % 3600) % 60;
					return `${hours}시간 ${minutes}분 ${seconds}초`;
				}

			},
		};


		let input_view = {
			title: "원본",
			data: "seq",
			className: "dt-center",
			render: function (data, type, row, meta) {


				return `<img  role="button" width=${thumbnailWidth} height=${thumbnailHeight} onclick="window.that.handleResultClick('${row.project_name}','${row.seq}', 'input')" 
					 src='${process.env.REACT_APP_API_PATH}/dev/public/download/image?project_name=${row['project_name']}&seq=${row["seq"]}&type=input'>`;


			},
		};
		let model_view = {
			title: "모델",
			data: "seq",
			className: "dt-center",
			render: function (data, type, row, meta) {
				if (row["status"] != "succeed") {
					return "처리 중";
				} else {

					return `<img  role="button" width=${thumbnailWidth} height=${thumbnailHeight} onclick="window.that.handleResultClick('${row.project_name}','${row.seq}', 'model')" 
					 src='${process.env.REACT_APP_API_PATH}/dev/public/download/image?project_name=${row['project_name']}&seq=${row["seq"]}&type=model'>`;

				}
			},
		};
		let output_view = {
			title: "결과보기",
			data: "seq",
			className: "dt-center",
			render: function (data, type, row, meta) {
				if (row["status"] != "succeed") {
					return "처리 중";
				} else {

					return `<img  role="button" width=${thumbnailWidth} height=${thumbnailHeight} onclick="window.that.handleResultClick('${row.project_name}','${row.seq}', 'output')" 
					 src='${process.env.REACT_APP_API_PATH}/dev/public/download/image?project_name=${row['project_name']}&seq=${row["seq"]}&type=output'>`;

				}
			},
		};


		let getTaskInfo = {
			title: "TaskInfo",
			data: "seq",
			className: "dt-center",
			render: function (data, type, row, meta) {
				if (row["status"] != "succeed") {
					return "processing";
				} else {

					return `<button class="btn btn-primary btn-sm" onclick="window.that.getTaskInfo('${row.seq}')" >Info</button>`

				}
			},
		};
		let arr = [idx, seq, customer_name, client_id, project_name, file_name, category_1, product_id, status, created_datetime, requested_datetime, completed_datetime, elasped_time, input_view, model_view, output_view, getTaskInfo];





		return arr;
	};
	initializeDatatable = () => {
		const that = this;
		const { data } = this.state;
		if (this.oTable) {
			this.oTable.destroy();
			this.test.current.innerHTML = "";
		}
		this.oTable = $(this.test.current).DataTable({
			order: [[0, "desc"]],
			iDisplayLength: this.state.max_rows,
			searching: true,
			processing: true,
			responsive: true,
			language: {
				processing: "Loading...",
			},
			serverSide: true,
			ajax: this.getDataTableAjax(),
			columns: this.getColumnSetting(),
			initComplete: function () {
				$(".dataTables_filter input").unbind();
				$(".dataTables_filter input").bind("keyup", function (e) {
					var code = e.keyCode || e.which;
					if (code == 13) {
						that.oTable.search(this.value).draw();
					}
				});
			},
		});
		$(".dataTables_wrapper").css("width", "100%");
		$("#DataTables_Table_0_length").on("change", function (val) {
			that.setState({ data: data.set("max_rows", parseInt($("#DataTables_Table_0_length  option:selected").text())) });
		});
	};
	refreashTable = (event) => {
		this.initializeDatatable();
	};
	async componentDidMount() {
		document.addEventListener("mousedown", this.handleClickOutside);
		this.getProjectList();

	}
	getModeKor = () => {

		switch (this.state.selectedMode) {
			case "all":
				return "전체 목록";
			case "processing":
				return "진행 목록";
			case "uploaded":
				return "대기 목록";
			case "completed":
				return "완료 목록";
			case "failed":
				return "실패 목록";
			case "canceled":
				return "취소 목록";
			default:
				return "???";
		}
	};


	handleResultClick = (project_name, seq, type) => {
		let url = "";
		if (type == "input") {
			url = this.generateImageUrl(project_name, seq);
		} else if (type == "model") {
			url = this.generateModelUrl(project_name, seq);
		} else if (type == "output") {
			url = this.generateResultUrl(project_name, seq);
		}

		this.setState({ isModalOpen: true, modalImageSrc: url });

	};
	generateImageUrl(project_name, seq) {

		return `${process.env.REACT_APP_API_PATH}/dev/public/download/image?project_name=${project_name}&seq=${seq}&type=input`
	}

	generateModelUrl(project_name, seq) {

		return `${process.env.REACT_APP_API_PATH}/dev/public/download/image?project_name=${project_name}&seq=${seq}&type=model`
	}

	generateResultUrl(project_name, seq) {

		return `${process.env.REACT_APP_API_PATH}/dev/public/download/image?project_name=${project_name}&seq=${seq}&type=output`
	}
	archiveTask = async (seq) => {



		this.setState({ onDownloadAll: true })
		try {
			const s3SignedUrlData = await axios({
				method: 'GET',
				url: `${process.env.REACT_APP_API_PATH}/dev/admin/task/export`,
				params: {
					seq: seq,

				}

			});
			const url = JSON.parse(s3SignedUrlData.data.body).downloadUrl;
			console.log(url);
			window.open(url, '_blank')

		}
		catch (e) {
			alert("파일 키가 없거나 올바른 요청이 아닙니다.")
			console.log(e)
		}

	};
	render() {
		const selectedData = this.state.currentTask;


		//<TableBox options={options} sizeClass={"col-md-6"}></TableBox>
		return (
			<Fragment>
				{this.state.isModalOpen && (
					<div className="modals">
						<div className="modal-contents" style={{ "overflow-y": "scroll", alignContent: "center" }} ref={this.modalRef}>
							<img style={{
								display: "block",
								"margin-left": "auto",
								"margin-right": "auto",
								"object-fit": "contain",
								"max-height": 800
							}} src={this.state.modalImageSrc} alt="Large version" className="modal-image" />
						</div>
					</div>)}
				{this.state.isInfoModalOpen && (
					<div className="modals">
						<div className="modal-contents" style={{ "overflow-y": "scroll", height: 500 }} ref={this.modalRef}>

							<div className="x_panel" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>

								<button className="close-btn" onClick={() => this.closeModal(null)}>
									X
								</button>
								<Col md={11}>
									<table style={{ width: "100%", border: "1px solid black", "border-collapse": "collapse" }}>
										<thead>
											<tr>
												<th style={{ width: "10%" }}>Prop</th>
												<th style={{ width: "50%" }}>Value</th>
											</tr>
										</thead>
										<tbody>

											<tr>
												<td className="tb_td">idx</td>
												<td className="tb_td">{selectedData.idx}</td>
											</tr>
											<tr>
												<td className="tb_td">seq</td>
												<td className="tb_td">{selectedData.seq}</td>
											</tr>

											<tr>
												<td className="tb_td">ChatGPT Prompt</td>
												<td className="tb_td">{this.state.currentEnvParm.prompt_1}</td>
											</tr>
											<tr>
												<td className="tb_td">FalAI Base</td>
												<td>{this.state.currentEnvParm.prompt_2}</td>
											</tr>
											<tr>
												<td className="tb_td">Fal.ai Final</td>
												<td className="tb_td">{selectedData.image_prompt}</td>
											</tr>

										</tbody>
									</table>
								</Col>



							</div>

						</div>
					</div>)}
				<HeaderComponent signOut={this.props.cognito.signOut} setStage={this.props.setStage} stage={this.props.stage}></HeaderComponent>
				<div className="main_contents">
					<Container fluid>
						<Row>
							<div className="x_panel" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
								<Col md={4}>


								</Col>

								<Col md={4} style={{ alignItems: "center", display: "flex", fontSize: 25, color: "black", fontWeight: 800, justifyContent: "center" }}>
									{this.getModeKor()}
								</Col>

								<Col md={4}>

									<Col md={4}>
										<ButtonGroup>
											<Button
												color="info"
												onClick={() => {
													this.initializeDatatable();
												}}
											>
												Refresh
											</Button>
										</ButtonGroup>
									</Col>
									<Col md={4}>
										<Input value={this.state.selectedProjectName} onChange={(e) => this.onValueChanged("selectedProjectName", e.target.value)} id="selectedProjectName" name="selectedProjectName" type="select">

											{this.state.project_list.map((item) => (
												<option value={item.project_name}>{item.project_name}</option>
											)
											)}


										</Input>
									</Col>

									<Col md={4}>
										<Input value={this.state.selectedMode} onChange={(e) => this.onValueChanged("selectedMode", e.target.value)} id="selectedMode" name="selectedMode" type="select">
											<option value={"all"}>목록 선택(전체보기)</option>
											<option value={"succeed"}>완료 목록</option>
											<option value={"processing"}>진행 목록</option>
											<option value={"failed"}>실패 목록</option>


										</Input>
									</Col>
								</Col>
							</div>
							<div className="x_panel" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
								<Table bordered style={{ width: "100%" }} innerRef={this.test} />
							</div>
						</Row>
					</Container>
				</div>
			</Fragment>
		);
	}
}

export default AllPage;
